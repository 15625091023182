:root {
  --primary-color: #f66c69;
  --primary-hover-color: #d65d5a;
  --border-color: #e0e0e0;
  --offwhite: #f7f7f7;
  --lightGrey: #f9f9f9;
  --darkGrey: #404040;
  --text-color: #3a3a3a;
  --error-color: #ff0000;
  --white: #fff;
  --black: #000;
  --input: #fff;
  --EEE: #eee;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--black);
  text-align: left;
  background-color: var(--white);
}

input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
  line-height: 1.6;
  font-family: inherit;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin-top: 0;
  /* color: var(--text-color); */
  font-size: 14px;
}

p a {
  text-decoration: underline !important;
}

ul:not(.list-styled) {
  list-style: none;
  padding: 0;
  margin: 0;
}

p:last-of-type {
  margin-bottom: 0;
}

ol {
  padding-left: 15px;
}

.text-center {
  text-align: center !important;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  z-index: -1;
}

table {
  width: 100%;
}

a,
a:hover,
button {
  cursor: pointer;
  color: inherit;
}

a,
button,
textarea {
  transition: ease 0.3s;
  -webkit-transition: ease 0.3s;
  -moz-transition: ease 0.3s;
  text-decoration: none !important;
  outline: none !important;
  color: inherit;
}

a:hover,
button:hover {
  color: inherit;
}

button {
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
  width: 100%;
}

input[type="submit"] {
  transition: ease 0.3s;
  -webkit-transition: ease 0.3s;
  -moz-transition: ease 0.3s;
  text-decoration: none !important;
  outline: none !important;
}

::-moz-input-placeholder,
::-moz-placeholder {
  opacity: 0.7;
  color: var(--text-color);
}

::-webkit-input-placeholder,
::-webkit-placeholder {
  opacity: 0.7;
  color: var(--text-color);
}

input-placeholder,
placeholder {
  opacity: 0.7;
  color: var(--text-color);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill {
  background-color: var(--input) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--input) inset;
  -webkit-transition-property: var(--input) !important;
  /* background-clip: content-box; */
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--black) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--input) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(158, 158, 158, .5);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--offwhite);
}

html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

.switch-wrapper {
  flex: 1 0 auto;
}

.switch-wrapper>div {
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1070px;
  padding: 0 25px;
  margin: auto;
}

label.checkout_checkbox {
  font-weight: 400;
  margin: 0;
  position: relative;
  cursor: pointer;
  /* color: var(--text-color); */
  font-size: 14px;
  display: block;
  padding-left: 30px;
}

label.checkout_checkbox.checkout_radio {
  padding: 12px 12px 12px 40px;
}

label.checkout_checkbox input {
  display: none;
}

label.checkout_checkbox input+span {
  width: 18px;
  height: 18px;
  border: var(--border-color) 1px solid;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 10px 0 0;
  position: relative;
  -webkit-transition: cubic-bezier(1, 0.49, 0, 0.7) 0.2s;
  -moz-transition: cubic-bezier(1, 0.49, 0, 0.7) 0.2s;
  transition: cubic-bezier(1, 0.49, 0, 0.7) 0.2s;
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 3px;
}

label.checkout_checkbox.checkout_radio input+span {
  margin-top: 0;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

label.checkout_checkbox input+span:before {
  content: '\2713 ';
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--white);
  font-size: 12px;
  text-align: center;
  line-height: 18px;
}

label.checkout_checkbox input+span:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--black);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

label.checkout_checkbox input:checked+span {
  border-color: var(--black);
  box-shadow: var(--black) 0 0 0 9px inset;
}

label.checkout_checkbox input:checked+span::after {
  animation: pulse 0.4s;
  animation-fill-mode: forwards;
}

label.checkout_checkbox.checkout_radio input+span {
  border-radius: 50%;
}

label.checkout_checkbox.checkout_radio input:checked+span {
  box-shadow: var(--black) 0 0 0px 6px inset;
}

label.checkout_checkbox.checkout_radio input+span:before {
  content: "";
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.checkout_btn_outer {
  text-align: center;
  padding: 30px 0;
}

.checkout_btn {
  position: relative;
  padding: 20px;
  background-color: var(--primary-color);
  color: var(--white);
  display: inline-block;
  min-width: 300px;
  font-size: 18px;
  letter-spacing: 1px;
  border-radius: 5px;
}

.checkout_btn:hover {
  /* color: var(--white); */
  opacity: 0.8;
}

.checkout_btn span {
  margin-left: 10px;
  vertical-align: middle;
}

.checkout_btn span svg path.one,
.checkout_btn span svg path.two {
  opacity: 0;
  visibility: hidden;
}

.checkout_btn:hover span svg path {
  animation: opacity 0.8s forwards infinite;
  animation-delay: 0.2s;
}

.checkout_btn:hover span svg path.two {
  animation-delay: 0.3s;
}

.checkout_btn:hover span svg path.one {
  animation-delay: 0.5s;
}

.btn {
  padding: 12px 25px;
  border-radius: 5px;
  display: inline-block;
  border: 0;
  background-color: var(--primary-color);
  color: var(--white);
  letter-spacing: 0.5px;
}

.btn.btn-sm {
  font-size: 12px;
  padding: 10px 20px;
}

.btn:hover {
  /* background-color: var(--primary-hover-color);
  color: var(--white); */
  opacity: 0.8;
}

.link {
  color: #4830ff !important;
}

.disabled,
.disabled * {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.sm_show {
  display: none;
}

.sm_hide {
  display: block;
}

.md_show {
  display: none;
}

.md_hide {
  display: block;
}

.height {
  height: 100%;
}

.custom_alert {
  position: fixed;
  top: 0;
  left: 50%;
  padding: 15px 30px;
  background: #62a965;
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 20%) 2px 2px 10px;
  min-width: 200px;
  text-align: center;
  font-weight: 500;
  animation: alert 3.1s linear;
  z-index: 9999999999999;
}

.custom_alert.error {
  background: red;
}

@keyframes alert {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -100%);
  }

  5% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  94% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
}

.custom_alert p {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}


@keyframes pulse {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
    -moz-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.3;
    visibility: visible;
  }

  to {
    -webkit-transform: translate(-50%, -50%) scale(3.5);
    -moz-transform: translate(-50%, -50%) scale(3.5);
    transform: translate(-50%, -50%) scale(3.5);
    opacity: 0;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}


.linear_progress {
  display: block;
  background-color: #e6e6e6;
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
}

.linear_progress_bar {
  background-color: #666;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: bar linear infinite 2s;
}

@keyframes bar {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(500%);
  }
}

.btn.custom_btn {
  height: 100%;
  font-size: 14px;
  min-width: 110px;
}

.justify-content-between {
  justify-content: space-between !important;
}

@media screen and (max-width: 992px) {
  .container {
    max-width: 700px;
  }

  .height {
    height: auto;
  }

  .md_show {
    display: block;
  }

  .md_hide {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sm_show {
    display: block;
  }

  .sm_hide {
    display: none;
  }

  .checkout_btn_outer {
    padding-bottom: 0;
  }

  .custom_alert {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  h5,
  h6 {
    font-size: 14px;
  }

  .container {
    padding: 0 15px;
  }
  .checkout_btn {
      padding: 15px 20px;
      min-width: 200px;
      font-size: 16px;
  }
}

.react-tel-input .flag-dropdown {
  display: none;
}

.MuiFormControl-root .react-tel-input .form-control {
  padding-left: 15px;
}
.align-items-end{
  align-items: flex-end !important;
}


@media (prefers-color-scheme: dark) {
  :root{
    --white: #000;
    --black: #fff;
    --input: #0a0a0a;
    --text-color: #fff;
    --EEE: #0a0a0a;
    --darkGrey: #d3d3d3;
  }
  .InputContainer .InputElement, input::-webkit-input-placeholder{
    color: #fff;
  }
}